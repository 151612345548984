import React, { useState } from 'react';
import '../styles/AuthPage.css';

const AuthPage = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [message, setMessage] = useState('');

  const handleSendOtp = async () => {
    try {
      const response = await fetch('https://auth.aegiscoms.com/api/send-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber }),
      });
      if (response.ok) {
        setOtpSent(true);
        setMessage('OTP sent successfully');
      } else {
        setMessage('Error sending OTP');
      }
    } catch (error) {
      setMessage('Error sending OTP');
    }
  };

  const handleLogin = async () => {
    try {
      const response = await fetch('https://auth.aegiscoms.com/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phoneNumber, otp }),
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('jwtToken', data.token);
        window.location.href = '/messages';
      } else {
        setMessage('Invalid OTP');
      }
    } catch (error) {
      setMessage('Error verifying OTP');
    }
  };

  return (
    <div className="auth-container">
      <h1>Login</h1>
      <div className="auth-form">
        <input
          type="text"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        {otpSent && (
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        )}
        {!otpSent ? (
          <button onClick={handleSendOtp}>Send OTP</button>
        ) : (
          <button onClick={handleLogin}>Login</button>
        )}
        <p>{message}</p>
      </div>
    </div>
  );
};

export default AuthPage;
