import React, { useEffect, useState } from 'react';
import '../styles/MessagePage.css';
import { useNavigate } from 'react-router-dom';

const MessagePage = () => {
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      navigate('/auth');
    } else {
      // Fetch messages or initialize the message handling here
      setMessages(['Welcome to the messaging app!', 'This is your first message.']);
    }
  }, [navigate]);

  return (
    <div className="message-container">
      <h1>Messages</h1>
      <div className="message-list">
        {messages.map((message, index) => (
          <div key={index} className="message-bubble">
            {message}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MessagePage;
